import React from 'react';
import './style.scss';
import { TVChartContainer } from '../components/TVChartContainer/index';

function getParams() {
  return new URLSearchParams(window.location.search);
}

function getSymbolFromParams(key) {
  const queryParams = getParams();
  return queryParams.get(key) || "I-BTC_INR";
}

function getThemeFromParams(key) {
  const queryParams = getParams();
  return queryParams.get(key)||'dark';
}


function Chart() {
  return (
    <section id="graph">
      <TVChartContainer symbol={getSymbolFromParams('market')} theme={getThemeFromParams('theme')} />
    </section>
  );
}

export default Chart;
