import React, { useEffect, useState, createRef } from 'react';
import './index.scss';
import { widget } from '../../charting_library';
import ConfigData from '../../customDatafeeds/ConfigData';
import CustomDatafeed from '../../customDatafeeds/CustomDatafeed';
import PropTypes from 'prop-types';


const lightThemeTimeButton = {
  selected: {
    color: '#22223b',
    fontWeight: 'bold',
  },
  default: {
    color: '#495057',
    fontWeight: '300',
  }
};

const darkThemeTimeButton = {
  selected: {
    color: '#fff75e',
    fontWeight: 'bold',
  },

  default: {
    color: '#495057',
    fontWeight: '300',
  }
};

function baseAll(buttons, theme) {
  buttons.forEach((btn) => {
    if (theme === 'dark') {
      btn.style.color = darkThemeTimeButton.default.color;
      btn.style.fontWeight = darkThemeTimeButton.default.fontWeight;
      return;
    }
    btn.style.color = lightThemeTimeButton.default.color;
    btn.style.fontWeight = lightThemeTimeButton.default.fontWeight;
  });
}

function highlight1Dbutton(buttons, theme) {
  buttons.forEach(button => {
    if (button.innerHTML === '1d') {
      if (theme === 'dark') {
        button.style.color = darkThemeTimeButton.selected.color;
        button.style.fontWeight = darkThemeTimeButton.selected.fontWeight;
        return;
      }
      button.style.color = lightThemeTimeButton.selected.color;
      button.style.fontWeight = lightThemeTimeButton.selected.fontWeight;
    }
  })
}
const resolutions = new Map();
resolutions.set('1', '1m');
resolutions.set('5', '5m');
resolutions.set('15', '15m');
resolutions.set('60', '1h');
resolutions.set('1D', '1d');
resolutions.set('1W', '1w');
resolutions.set('1M', '1M');
resolutions.set("12M", "1y");

let widgetConfig = {
  timezone: 'Asia/Kolkata',
  container: 'tv_chart_container',
  datafeed: UDFCompatibleDatafeed,
  width: '100%',
  height: '100%',
  // header_widget , header_symbol_search , header_resolutions
  disabled_features: [
    'header_symbol_search',
    'header_settings',
    'header_undo_redo',
    'header_resolutions',
    'header_compare',
  ],
  interval: '1D',
  numerical_formatting: {
    decimal_sign: 5 // Set precision to 4 decimal places
  },
  // debug:true,
  // supported_resolutions:ConfigData.supported_resolutions,
  library_path: 'charting_library/',
};

TVChartContainer.propTypes = {
  symbol: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

export function TVChartContainer({ symbol, theme }) {
  const ref = createRef();
  const [tvWidget, setTvWidget] = useState(null);
  widgetConfig.symbol = symbol;
  if (theme) widgetConfig.theme = theme;

  useEffect(() => setTvWidget(() => new widget(widgetConfig)), []);

  useEffect(() => {
    if (!tvWidget) return;

    const createButton = (time, title, cb) => {
      const button = tvWidget.createButton();
      button.setAttribute('title', title);
      button.style.fontSize = '12px';

      button.addEventListener('click', () => {
        tvWidget.chart().setResolution(time);
        cb();
        if (theme === 'dark') {
          button.style.color = darkThemeTimeButton.selected.color;
          button.style.fontWeight = darkThemeTimeButton.selected.fontWeight;
          return;
        }
        button.style.color = lightThemeTimeButton.selected.color;
        button.style.fontWeight = lightThemeTimeButton.selected.fontWeight;
        // button.style.color = '#22223b';
        // button.style.color = '#fff75e';
        // button.style.fontWeight = 'bold';
      });

      button.innerHTML = title;
      return button;
    };

    tvWidget.onChartReady(() => {


      tvWidget.applyOverrides({ "scalesProperties.fontSize": 14 });


      tvWidget.headerReady().then(() => {
        const timeBtn = tvWidget.createButton();
        timeBtn.setAttribute('title', 'TIME');
        timeBtn.innerHTML = 'TIME';
        timeBtn.style.fontWeight = 'bold';
        timeBtn.style.fontSize = '12px';

        const buttons = ConfigData.supported_resolutions.map((res) => createButton(res, resolutions.get(res), () => baseAll(buttons, theme)));

        highlight1Dbutton(buttons, theme);
      });
    });

    // const getLatestCandle = () => {
    //   const symbol = tvWidget.symbolInterval().symbol.split(':')[1];
    //   const resolution = tvWidget.symbolInterval().interval;
    //   console.log(symbol, resolution);

    //   // tvWidget.chart().getBar(symbol, resolution, -1, (bar) => {
    //   //   console.log('lastbar', bar);
    //   // });
    // };

    // setInterval(() => getLatestCandle(), 5000);
  }, [tvWidget]);

  useEffect(() => {
    return () => {
      if (tvWidget !== null) {
        tvWidget.remove();
        setTvWidget(() => null);
      }
    };
  }, []);

  //------------------------------------------------------------ Render

  return (
    <div ref={ref} className={'TVChartContainer'} id="tv_chart_container" />
  );
}
