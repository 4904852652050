import axios from 'axios';
import { io } from 'socket.io-client'

function initSockets(url, channelToSubscription) {
  const socket = io(url, {
    path: `/socket.io`,
    reconnectionDelayMax: 10000,
    transports: ["websocket"]
  })

  socket.on('connect', () => {
    console.log('connected');
    const cache = channelToSubscription.get('cache')
    if (cache) {
      // console.log(cache)
      socket.emit('subscribe', cache)
    }
  })

  socket.on('disconnect', (reason) => {
    console.log('[socket] Disconnected:', reason)
  })

  socket.on('error', (error) => {
    console.log('[socket] Error:', error)
  })

  socket.on('data', (data) => {
    const candle = JSON.parse(data).newCandle;
    const { time, ...rest } = candle;
    const callback = channelToSubscription.get('handler');
    const payload = { ...rest, time: Date.now() };
    if (callback) callback(payload);
  })

  return socket
}
function getParams() {
  return new URLSearchParams(window.location.search);
}

export default class {
  constructor() {
    this.resMapping = {
      '1': '1m',
      '5': '5m',
      '15': '15m',
      '60': '1h',
      '1D': '1d',
      '1W': '1w'
    }
    const exchange = getParams().get('exchange') || 'cdx';
    if (exchange === 'sc') {
      this.channelToSubscription = new Map();
      const url = process.env.REACT_APP_BACKEND_URL;
      this.socket = initSockets(url, this.channelToSubscription)
      return;
    }
    this.cdxBaseUrl = `https://public.coindcx.com/market_data/candles?`;
  }

  async getBars(info, callback) {
    const exchange = getParams().get('exchange') || 'cdx';
    if (exchange === 'sc') {
      this.socket.emit('getBars', { ...info, exchange: 'sc' });
      this.socket.on('historic-bars', (data) => callback(JSON.parse(data)));
      return;
    }

    const interval = this.resMapping[info.interval.toString()];
    if (!interval) {
      callback({ s: 'error', bars: [] });
      return;
    }
    const url = `${this.cdxBaseUrl}pair=${info.symbol}&interval=${interval}&startTime=${info.from}&endTime=${info.to}`;

    axios.get(url)
      .then(res => {
        callback({ s: 'ok', bars: res.data.reverse() });
      })
      .catch(e => {
        callback({ s: 'error', bars: [] });
        console.error('getBars error: ', e);
      });
  }

  subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
    lastDailyBar,
  ) {
    const exchange = getParams().get('exchange') || 'cdx';
    if (exchange === 'sc') {
      const info = { symbolInfo, resolution, lastDailyBar, exchange };
      this.channelToSubscription.set('cache', info);
      this.channelToSubscription.set('handler', onRealtimeCallback);
      this.socket.emit('subscribe', info);
      return;
    }

    const interval = this.resMapping[resolution];
    if (!interval) {
      callback({ s: 'error', bars: [] });
      return;
    }

    clearInterval(this.subStream);
    const url = `${this.cdxBaseUrl}pair=${symbolInfo.ticker}&interval=${interval}&limit=1`;

    this.subStream = setInterval(async () => {
      const { data } = await axios.get(url);
      onRealtimeCallback(data[0]);
    }, 1000 * 60);
  }

  unsubscribeFromStream(subscriberUID) {
    // console.log('unsubscribeFromStream', subscriberUID);
  }
}
